import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import CreateFragebogen from '../views/create/CreateFragebogen.vue'
import EditFragebogen from '../views/create/EditFragebogen.vue'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import UserView from '../views/UserView.vue'
import CreateSubmission from '../views/create/CreateSubmission.vue'
import AnswerQuestionnaire from '../views/AnswerQuestionnaire.vue'
import wrongCode from '../views/error/wrongCode.vue'
import ShowQRCode from '../views/ShowQRCode.vue'
import ListSubmissions from '@/views/ListSubmissions.vue'
import subFinished from '../views/error/subFinished.vue'
import DatenSchutz from '@/views/DatenSchutz.vue'
import Impressum from '@/views/ImpressumView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/me',
    name: 'user-detail',
    component: UserView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/create-fragebogen',
    name: 'create-fragebogen',
    component: CreateFragebogen
  },
  {
    path: '/edit-fragebogen',
    name: 'edit-fragebogen',
    component: EditFragebogen
  },
  // {
  //   path: '/create-submission',
  //   name: 'create-submission',
  //   component: CreateSubmission
  // },
  {
    path: '/create-submission/:patid?',
    name: 'create-submission',
    component: CreateSubmission,
    props: true
  },
  {
    path: '/fragebogen/:submission',
    name: 'fragebogen',
    component: AnswerQuestionnaire,
    props: true
  },
  {
    path: '/show-qr',
    name: 'show-qr',
    component: ShowQRCode
  },
  {
    path: '/list-submissions',
    name: 'list-submissions',
    component: ListSubmissions
  },
  {
    path: '/code-failure/:code',
    name: 'wrong-code',
    component: wrongCode,
    props: true
  },
  {
    path: '/sub-finished',
    name: 'sub-finished',
    component: subFinished
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: DatenSchutz
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: Impressum
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
