
import { useUserStore } from '@/store/UserDetail'
import { defineComponent } from 'vue'
import {ref} from 'vue'
import {useAuthStore} from '../store/UserAuth'

export default defineComponent ({
    setup() {
        const authStore = useAuthStore()
        const userStore = useUserStore()
        const username = ref<string>('')
        const password = ref<string>('')
        
        return { username, password, authStore, userStore }
    },
})
