import { defineStore } from "pinia";
import { useAuthStore } from "./UserAuth";
import Type from '../types/Type'
import Question from '../types/Question'

export const useFragebogenStore = defineStore('fragebogen', {
    state: () => ({
        title: '' as string,
        subtitle: '' as string,
        description: '' as string,
        fragen: [] as Question[],
        types: [] as Type[],
        id: null as number | null,
        fragenToDelete: [] as number[],
        need_to_sign: false as boolean
    }),
    actions: {
        async fetchTypes() {
            const AuthStore = useAuthStore()
            const token = AuthStore.access
            console.log("TOKEN")
            const options = {
                method: 'OPTIONS',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${token}`
                },
            }
            try {
                const data = await fetch(`${process.env.VUE_APP_ROOT_BACKEND}/fragebogen/fragen/`, options)
                if (data.ok) {
                    const resp = await data.json()
                    this.types = await resp.actions.POST.typ.choices
                } else {
                    console.log("nicht ok")
                }
                
            } catch(err) {
                console.log(err)
                return err
            }
        }
    }
})