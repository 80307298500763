import { defineStore } from "pinia";
import Questionnaire from "@/types/Questionnaire";

export const useEditFBStore = defineStore('editFragebogen', {
    state: () => ({
        fragebogenListe: [] as Questionnaire[]
    }),
    actions: {
        async fetchFragebogenListe() {
            try {
                const resp = await fetch(`${process.env.VUE_APP_ROOT_BACKEND}/fragebogen/auswahl/`)
                if (resp.ok) {
                    const data = await resp.json()
                    this.fragebogenListe = await data
                    return data
                } else {
                    console.log("resp")
                }
            } catch(error) {
                    console.log(error)
                }
        }
    }
})