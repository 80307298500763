
import { useAuthStore } from "@/store/UserAuth";
import { useUserStore } from "@/store/UserDetail"
import { defineComponent } from "vue";


export default defineComponent({
    setup() {
        const userStore = useUserStore()
        const authStore = useAuthStore()
        return { authStore, userStore }
    }
})
