import Answer from "@/types/Answer";
import Questionnaire from "@/types/Questionnaire";
import { defineStore } from "pinia";

export const useSubmissionStore = defineStore('submission', {
    state: () => ({
        submissionCode: '' as string,
        answers: [] as Answer[],
        submissionQuestionnaire: null as null | Questionnaire,
        signature: '' as string
    })
})