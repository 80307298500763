import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap"
import './index.css'


createApp(App).use(createPinia()).use(router).mount('#app')
