import { defineStore } from 'pinia'
import router from '../router'
import { useUserStore } from './UserDetail'

export const useAuthStore = defineStore('UserAuth', {
  state: () => ({ 
    access: '',
    refresh: '',
    returnUrl: null,
    refreshTokenTimeout: 0
   }),
  actions: {
    async login(username:string, password:string) {
      const userStore = useUserStore()
      try {
        const body = { 
          username: username,
          password: password
        }
        const resp = await fetch(`${process.env.VUE_APP_ROOT_BACKEND}/fragebogen/api/token/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
        const data = await resp.json()
        this.access = data.access
        this.refresh = data.refresh
        localStorage.setItem('token', JSON.stringify(data))
        this.startRefreshTokenTimer()
        userStore.getUserDetail()
        router.push('/')
      } catch(error) {
        return error
      }
    },
    logout() {
      const userStore = useUserStore()
      this.access = this.refresh = '',
      localStorage.removeItem('token')
      userStore.user = {'id': 0, 'username': 'nicht angemeldet'}
      this.stopRefreshTokenTimer()
      router.push('/login')
    },
    async getToken() {
      if (localStorage.getItem('token')) {
        const tokens = await JSON.parse(localStorage.getItem('token') as string)
        if (tokens) {
          this.access = await tokens.access
          this.refresh = await tokens.refresh
          if (this.access && this.refresh) {
            return tokens
          } else {
            return null
          }
        } else {
          router.push('/login')
        }
      } else {
        console.log("jdslkfjsfusof980980")
        return null
      }
    },
    async refreshToken() {
      try {
        const accessToken = await this.getToken()
        const body = {
          refresh: this.refresh
        }
        const resp = await fetch(`${process.env.VUE_APP_ROOT_BACKEND}/fragebogen/api/token/refresh/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (resp.ok) {
          const data = await resp.json()
          accessToken.access = this.access = await data.access
          localStorage.setItem('token', JSON.stringify(accessToken))
          this.startRefreshTokenTimer()
        } else {
          this.logout()
        }        
      } catch(error) {
        console.log(error)
        this.logout()
        //router.push('/login')
      }
    },
    startRefreshTokenTimer() {
      // parse json object from base64 encoded jwt token
      const jwtBase64 = this.access.split('.')[1];
      const jwtToken = JSON.parse(atob(jwtBase64));

      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    },    
    stopRefreshTokenTimer() {
      clearTimeout(this.refreshTokenTimeout);
    },
    isLoggedIn() {
      if (!this.access && !this.refresh) {
        this.refreshToken()
      }
    },
    async checkLogIn () {
      await this.refreshToken()
      const t = this.access
      const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${t}`
          }
      }
      const resp = await fetch(`${process.env.VUE_APP_ROOT_BACKEND}/fragebogen/api/token/check/`, options)
      if (resp.ok) {
          console.log('TRUELOG')
      } else {
          console.log('FALSELOG')
          this.logout()
      }
  }
  },
})