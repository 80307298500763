import { defineStore } from 'pinia'
import { useAuthStore } from './UserAuth'
import User from '@/types/User'

export const useUserStore = defineStore('UserDetail', {
  state: () => ({ 
    user: {'id': 0, 'username': 'anonymous'} as User,
   }),
  actions: {
    async getUserId() {
        const authStore = useAuthStore()
        const tokens  = await authStore.getToken()
        if (tokens) {
            const base64 = await tokens.access.split('.')[1].replace('-', '+').replace('_', '/')
            const pl = await JSON.parse(atob(base64))
            return pl
        } else {
          return null
        }
    },
    async getUserDetail() {
        try {
            const pl = await this.getUserId()
            if (pl) {
              const user_id = pl.user_id
              const resp = await fetch(`${process.env.VUE_APP_ROOT_BACKEND}/fragebogen/me/${user_id}/`)
              const detail = await resp.json()
              this.user = detail
            } else {
              console.log("Nicht eingelogged")
            }
        } catch(error) {
            console.log(error)
        }
    }
  },
})
