<template>
    <div class="w-full max-w-lg m-auto my-20 text-center">
        <h1 class="text-center uppercase text-6xl">Hinweis</h1>
        <h2 class="text-center text-3xl mb-6">Fragebogen bereits geschlossen...</h2>
        <div class="my-6">
            <p class="mb-2">Der Fragebogen wurde bereits bearbeitet und ist aus Datenschutzgründen nicht mehr online verfügbar.</p>
            <p class="mb-2">Er liegt der Praxis bereits vor und ist dort in der Patientenkartei abgelegt.</p>
            <p class="mb-2">Falls Sie noch Änderungen oder Ergänzungen haben, melden Sie sich bitte in der Praxis.</p>
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="css">

</style>